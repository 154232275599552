.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.css-5wzqtd-singleValue {
  color: white !important;;
}
#sumsub-container {
  width: 100%;
  height: 500px;
  background-color: lightgrey;
}
.custom-selectmodal .ant-select-selector:hover,
.custom-selectmodal .ant-select-selector:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #534f69;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: transparent;
  color: white;
}
:where(.css-d2lrxs).ant-select .ant-select-arrow .anticon > svg {
  color: white;
}
:where(.css-d2lrxs).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: transparent;
}
:where(
    .css-dev-only-do-not-override-d2lrxs
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-d2lrxs
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  background-color: transparent;
  color: white;
}
:where(.css-d2lrxs).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
  color: white;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-select .ant-select-arrow {
  color: white;
  display: flex;
  align-items: center;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid transparent;
  background: transparent;
}
.ant-select-dropdown {
  background-color: black !important;
}
.ant-select-item {
  background-color: black !important;
  color: white !important;
}
.ant-select-selection-item {
  color: white !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.body {
  background-color: black;
  min-height: 100vh;
  color: white;
}
.newbody {
  background-color: #07001d;
  min-height: 100vh;
  color: white;
}
.account {
  background-color: #07001d;
  min-height: 100vh;
  color: white;
}

.background-div {
  background-image: url("../public/icon/blur.png");
  background-size: cover;
  background-position: center;
}
.signup-div {
  background-image: url("../public/icon/blur.png");
  background-size: cover;
  background-position: center bottom 20%; /* Adjust the position up from the bottom */
  width: 100%;
  height: auto !important;
  margin: 0 auto;
}
.newsignup-div {
  background-image: url("../public/icon/bg-account.png");
  background-size: cover;
  background-position: center top; /* Adjust the position up from the bottom */
  width: 100%;
  height: 100% !important;
  margin: 0 auto;
}
.shop-div {
  background-image: url("../public/icon/homebg.jpg");
  background-size: cover;
  background-position: center;
}

.ant-switch .ant-switch-handle::before {
  background-color: #404040 !important;
}
.bg-account {
  background-image: url("../public/icon/bg-account.png");
  background-size: cover;
  background-position: center bottom 100%;
  /* Adjust the position up from the bottom */
  width: 100%;
  height: auto !important;
  margin: 0 auto;
}
.custom-radio {
  appearance: none;
  border: 2px solid #9b9b9b; /* Initial border color */
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.custom-radio:checked {
  border-color: white;
}

.custom-radio:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}
.w-full.h-full {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  position: relative;
}

.w-full.h-full iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.navbar-bg-color {
  background-image: url("../public/icon/bg.png");
  background-size: cover;
  background-position: center;
}
.navbar-bg-color-dashboard {
  background-image: url("../public/icon/bg.png");
  background-size: cover;
  max-height: 100%;
  min-height: 100vh;
  background-position: center;
}
.custom-popover .ant-popover-inner {
  background-color: #211e34bf;
}
.custom-popover .ant-popover-arrow {
  display: none;
}
:where(.css-d2lrxs).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: transparent;
}
:where(.css-d2lrxs).ant-select .ant-select-arrow .anticon > svg {
  color: white;
}
:where(.css-d2lrxs).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
  color: white;
}
.fade-blur-in {
  opacity: 0;
  transform: translateX(-50px);
  filter: blur(10px);
  animation: fadeBlurIn 0.4s forwards;
}

@keyframes fadeBlurIn {
  to {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
  }
}

.fade-blur-out {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
  animation: fadeBlurOut 0.8s forwards;
}

@keyframes fadeBlurOut {
  to {
    opacity: 0;
    transform: translateX(50px);
    filter: blur(10px);
  }
}

.container {
  overflow: hidden;
}

.font-mono {
  font-family: monospace;
  font-weight: 500;
  font-size: large;
}
.custom-counter > li::before {
  content: "(" counter(list-item) ")";
  margin-right: 0.5em;
  font-weight: bold;
}

.scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.scroll-track {
  display: flex;
  width: calc(200% + 10px);
  animation: scroll 42s linear infinite;
}

.scroll-track.reverse-scroll {
  display: flex;
  width: calc(200% + 10px);
  animation: scroll 42s linear infinite;
  animation: reverse-scroll 42s linear infinite;
}

.scroll-track:hover,
.scroll-track.reverse-scroll:hover {
  animation-play-state: paused;
}

.scroll-track > div {
  flex-shrink: 0;
}
@media (max-width: 768px) {
  .scroll-track {
    animation: scroll 5s linear infinite; /* Faster animation for mobile */
  }

  .scroll-track.reverse-scroll {
    animation: reverse-scroll 5s linear infinite; /* Faster animation for mobile */
  }
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes reverse-scroll {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

.animate-expand {
  opacity: 0;
  transition: opacity 1s ease; /* Adjust duration and timing function as needed */
}

.animate-expand.active {
  opacity: 1;
}

/* body {
  font-family:  sans-serif;
  margin: 0;
  padding: 0;
  height: 200vh; 
} */

.hero_section {
  perspective: 1000px;
  filter: drop-shadow(24px 95px 39px rgba(194, 194, 194, 0.01));
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1199px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.hero_section img {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(17.2483deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg);
  transition: transform 0.1s ease-out;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.content {
  margin-top: 100vh;
  padding: 20px;
}

.expandable {
  transition: max-height 0.8s ease-in-out;
  overflow: hidden;
}

.expandable-content {
  transition: opacity 0.8s ease-in-out;
}

.hidden-content {
  max-height: 0;
  opacity: 0;
}

.visible-content {
  max-height: 500px;
  opacity: 1;
}

.affiliate-input {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-out;
}

.affiliate-input.show {
  max-height: 100px; /* Adjust as needed */
}
.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal h2 {
  margin-bottom: 20px;
}

.modal form input {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.modal form button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-dropdown-menu a {
  color: white !important;
}

.custom-dropdown-menu a:hover {
  color: #ffd700 !important; /* Yellow color */
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: transparent;
  color: white;
}
:where(
    .css-dev-only-do-not-override-d2lrxs
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-d2lrxs
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  background-color: transparent;
  color: white;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-select .ant-select-arrow {
  color: white;
  display: flex;
  align-items: center;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  margin-top: -6px;
  font-size: 12px;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid transparent;
  background: transparent;
}
.custom-selectmodal .ant-select-selector:hover,
.custom-selectmodal .ant-select-selector:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select-dropdown {
  background-color: black !important;
}
.ant-select-item {
  background-color: black !important;
  color: white !important;
}
.ant-select-selection-item {
  color: white !important;
}
:where(.css-dev-only-do-not-override-d2lrxs).ant-dropdown .ant-dropdown-menu {
  background-color: #222222 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
:where(.css-d2lrxs).ant-dropdown .ant-dropdown-menu {
  background-color: #222222 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select .ant-select-selector {
  background-color: #404040 !important;
  color: white !important;
  border: none !important;
}
.custom-select .ant-select-selection-placeholder {
  color: #f1f1f183; /* Change this to your desired placeholder color */
  opacity: 1; /* Ensure full opacity if needed */
}

.chart-container {
  position: relative;
  padding: 20px;
}

.dropdown-container {
  margin-bottom: 10px;
}

.dropdown {
  padding: 2px;
  background-color: #000;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
  padding-left: 12px;
  gap: 1px;
}

.timeframe-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 4px;
}

.time-button {
  padding: 2px 5px;
  background-color: #404040;
  color: #ffffff80;
  opacity: 70;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 12px;
  font-weight: 600;
}

.time-button.active {
  color: #fff;
  border: none;
}

.time-button:hover {
  background-color: #444;
}
